export const onClientEntry = () => {
  require('default-passive-events');
  require('typeface-noto-sans');
  if (!(`IntersectionObserver` in window)) {
    return import(`intersection-observer`);
  }
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This site has been updated. ` + `Reload to display the latest version?`,
  );
  if (answer === true) {
    window.location.reload();
  }
};
